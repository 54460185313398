import React from 'react';
import { Segment, Form } from 'semantic-ui-react';
import axios from 'axios';

import { RegisterFormState, UploadProgress } from './RegisterForm.interface';
import { messages } from '../../messages';
import { Messages } from '../../messages/Messages.interface';
import { ConfirmationModal } from '../ConfirmationModal/ConfirmationModal';
import { AgbModal } from './AgbModal';
import { AgbCheckbox } from './AgbCheckbox';
import { SubmitButton } from './SubmitButton';
import { Formik, Form as FormikForm, FormikProps, FormikActions } from 'formik';
import { FormFieldType } from './FormFieldType.enum';
import { RegisterFormField } from './registerFormElements/RegisterFormField';
import { RegisterFormSelect } from './registerFormElements/RegisterFormSelect';
import { SemanticUiForm } from '../SemanticUiForm';
import {
  RegisterFormImageUpload,
  ImageFieldValue,
} from './registerFormElements/RegisterFormImageUpload';
import { registerFormFields } from './registerFormInitialData';
import { mapKeysToValues } from '../../shared/helper/object.helper';
import { UploadProgressModal } from './UploadProgressModal';
import styled from 'styled-components';

const {
  registerForm: { fields },
}: Messages = messages;

type RegisterFormValues = {
  salutationSelect: string;
  firstName: string;
  lastName: string;
  street: string;
  streetNumber: string;
  zipcode: string;
  city: string;
  country: string;
  nationality: string;
  birthdayDay: string;
  birthdayMonth: string;
  birthdayYear: string;
  birthplace: string;
  preMobileSelection: string;
  mobileNumber: string;
  email: string;
  cardholderId: string;
  corporateWalletId: string;
  walletId: string;
  identifyDocumentFront: ImageFieldValue;
  identifyDocumentBack: ImageFieldValue;
  identifyDocumentSelfie: ImageFieldValue;
  creditCardPicture: ImageFieldValue;
};

const initialValues: RegisterFormValues = {
  salutationSelect: '',
  firstName: '',
  lastName: '',
  street: '',
  streetNumber: '',
  zipcode: '',
  city: '',
  country: '',
  nationality: '',
  birthdayDay: '',
  birthdayMonth: '',
  birthdayYear: '',
  birthplace: '',
  preMobileSelection: '',
  mobileNumber: '',
  email: '',
  cardholderId: '',
  corporateWalletId: '',
  walletId: '',
  identifyDocumentFront: {},
  identifyDocumentBack: {},
  identifyDocumentSelfie: {},
  creditCardPicture: {},
};

const getUploadProgressInPercent = ({
  uploadedInBytes,
  totalUploadSizeInBytes,
}: UploadProgress): number => {
  const uploadProgressInPercent =
    (uploadedInBytes / totalUploadSizeInBytes) * 100;
  return Math.round(uploadProgressInPercent);
};

const generateNewRequestCancelTokenSrc = () => {
  return axios.CancelToken.source();
};

const imageLoadingStartedAction = (
  state: RegisterFormState
): RegisterFormState => {
  return { ...state, isAnyImageLoading: true };
};

const imageLoadingFinishedAction = (
  state: RegisterFormState
): RegisterFormState => {
  return { ...state, isAnyImageLoading: false };
};

const toogleAgbCheckboxAction = (
  state: RegisterFormState
): RegisterFormState => {
  return { ...state, isAgbCheckboxChecked: !state.isAgbCheckboxChecked };
};

const RegisterFormImageUploadWrapper = styled.div`
  margin-bottom: 15px;
`;

const RegisterFormImageUploadesWrapper = styled.div`
  margin-top: 25px;
`;

export class RegisterForm extends React.Component<{}, RegisterFormState> {
  requestCancelTokenSrc = generateNewRequestCancelTokenSrc();

  constructor(props) {
    super(props);
    this.state = {
      isAgbModalOpen: false,
      registerFormFields,
      isConfirmationModalOpen: false,
      isUploadProgressModalOpen: false,
      isAnyImageLoading: false,
      isAgbCheckboxChecked: false,
      uploadProgress: {
        totalUploadSizeInBytes: 0,
        uploadedInBytes: 0,
      },
    };
  }

  handleOnSubmitForm = (
    registerFormValues: RegisterFormValues,
    formikActions: FormikActions<RegisterFormValues>
  ) => {
    const registerFormValuesJson = JSON.stringify(registerFormValues);

    this.closeUploadProgressModal();
    this.resetUploadProgress();
    formikActions.resetForm();
    // TODO: refactor in own (pure) function
    this.setState({ ...this.state, isAgbCheckboxChecked: false });
    this.openConfirmationModal();

    axios
      .post('mail.php', registerFormValuesJson, {
        headers: { 'Content-Type': 'application/json' },
        cancelToken: this.requestCancelTokenSrc.token,
        onUploadProgress: progressEvent => {
          this.openUploadProgressModal();
          this.setState({
            ...this.state,
            uploadProgress: {
              uploadedInBytes: progressEvent.loaded,
              totalUploadSizeInBytes: progressEvent.total,
            },
          });
        },
      })
      .then(() => {
        this.closeUploadProgressModal();
        this.resetUploadProgress();
        formikActions.resetForm();
        this.openConfirmationModal();
      })
      .catch(rejectReason => {
        this.closeUploadProgressModal();
        this.resetUploadProgress();
        alert(`${rejectReason}! Please contact the support.`);
      });
  };

  openAgbModal = () => {
    // this.setState({
    //   isAgbModalOpen: true,
    // });
  };

  updateState = (state: RegisterFormState) => {
    this.setState({ ...this.state, ...state });
  };

  closeAgbModal = () => {
    this.setState({
      ...this.state,
      isAgbModalOpen: false,
    });
  };

  openConfirmationModal = () => {
    this.setState({
      ...this.state,
      isConfirmationModalOpen: true,
    });
  };

  openUploadProgressModal = () => {
    this.setState({ ...this.state, isUploadProgressModalOpen: true });
  };

  closeUploadProgressModal = () => {
    this.setState({ ...this.state, isUploadProgressModalOpen: false });
  };

  handleOnCloseModal = () => {
    this.setState({
      ...this.state,
      registerFormFields,
      isConfirmationModalOpen: false,
    });
  };

  resetUploadProgress = () => {
    this.setState({
      ...this.state,
      uploadProgress: { totalUploadSizeInBytes: 0, uploadedInBytes: 0 },
    });
  };

  handleOnCancelUploadFormData = () => {
    this.requestCancelTokenSrc.cancel();
    this.requestCancelTokenSrc = generateNewRequestCancelTokenSrc();
    this.resetUploadProgress();
  };

  render() {
    const registerFormFieldNames = mapKeysToValues(initialValues);
    return (
      <Segment>
        <SemanticUiForm>
          <Formik
            initialValues={initialValues}
            onSubmit={(values, formikActions) =>
              this.handleOnSubmitForm(values, formikActions)
            }
          >
            {({ isSubmitting }: FormikProps<RegisterFormValues>) => (
              <FormikForm>
                <RegisterFormSelect
                  name={registerFormFieldNames.salutationSelect}
                  options={registerFormFields.salutationSelect.options}
                />
                <Form.Group widths="equal">
                  <RegisterFormField name={registerFormFieldNames.firstName} />

                  <RegisterFormField name={registerFormFieldNames.lastName} />
                </Form.Group>
                <Form.Group widths="equal">
                  <RegisterFormField name={registerFormFieldNames.street} />

                  <RegisterFormField
                    name={registerFormFieldNames.streetNumber}
                  />
                </Form.Group>
                <Form.Group widths="equal">
                  <RegisterFormField
                    name={registerFormFieldNames.zipcode}
                    type={FormFieldType.Number}
                  />

                  <RegisterFormField name={registerFormFieldNames.city} />
                </Form.Group>
                <RegisterFormField name={registerFormFieldNames.country} />
                <RegisterFormField name={registerFormFieldNames.nationality} />
                <Form.Group unstackable widths="3">
                  <RegisterFormField
                    name={registerFormFieldNames.birthdayDay}
                    type={FormFieldType.Number}
                  />

                  <RegisterFormField
                    name={registerFormFieldNames.birthdayMonth}
                    type={FormFieldType.Number}
                  />

                  <RegisterFormField
                    name={registerFormFieldNames.birthdayYear}
                    type={FormFieldType.Number}
                  />
                </Form.Group>
                <RegisterFormField
                  name={registerFormFieldNames.birthplace}
                  widths="equal"
                />
                <Form.Group unstackable widths="16">
                  <RegisterFormSelect
                    fluid
                    name={registerFormFieldNames.preMobileSelection}
                    width="4"
                    options={registerFormFields.preMobileSelection.options}
                  />
                  <RegisterFormField
                    name={registerFormFieldNames.mobileNumber}
                    width="12"
                    type={FormFieldType.Tel}
                  />
                </Form.Group>
                <RegisterFormField name={registerFormFieldNames.email} />
                <RegisterFormField
                  name={registerFormFieldNames.cardholderId}
                  type={FormFieldType.Number}
                />
                <RegisterFormField
                  name={registerFormFieldNames.corporateWalletId}
                  type={FormFieldType.Number}
                />
                <RegisterFormField
                  name={registerFormFieldNames.walletId}
                  type={FormFieldType.Number}
                />
                <Form.Group />

                <RegisterFormImageUploadesWrapper>
                  <RegisterFormImageUploadWrapper>
                    <RegisterFormImageUpload
                      isUploadDisabled={this.state.isAnyImageLoading}
                      onImageLoadingStarted={() =>
                        this.setState(imageLoadingStartedAction(this.state))
                      }
                      onImageLoadingFinished={() =>
                        this.setState(imageLoadingFinishedAction(this.state))
                      }
                      name={registerFormFieldNames.creditCardPicture}
                      title={`${fields.creditCardPicture}:`}
                    />
                  </RegisterFormImageUploadWrapper>

                  <RegisterFormImageUploadWrapper>
                    <RegisterFormImageUpload
                      isUploadDisabled={this.state.isAnyImageLoading}
                      onImageLoadingStarted={() =>
                        this.setState(imageLoadingStartedAction(this.state))
                      }
                      onImageLoadingFinished={() =>
                        this.setState(imageLoadingFinishedAction(this.state))
                      }
                      name={registerFormFieldNames.identifyDocumentFront}
                      title={`${fields.identifyDocumentFront}:`}
                    />
                  </RegisterFormImageUploadWrapper>

                  <RegisterFormImageUploadWrapper>
                    <RegisterFormImageUpload
                      isUploadDisabled={this.state.isAnyImageLoading}
                      onImageLoadingStarted={() =>
                        this.setState(imageLoadingStartedAction(this.state))
                      }
                      onImageLoadingFinished={() =>
                        this.setState(imageLoadingFinishedAction(this.state))
                      }
                      name={registerFormFieldNames.identifyDocumentBack}
                      title={`${fields.identifyDocumentBack}:`}
                    />
                  </RegisterFormImageUploadWrapper>

                  <RegisterFormImageUploadWrapper>
                    <RegisterFormImageUpload
                      isUploadDisabled={this.state.isAnyImageLoading}
                      onImageLoadingStarted={() =>
                        this.setState(imageLoadingStartedAction(this.state))
                      }
                      onImageLoadingFinished={() =>
                        this.setState(imageLoadingFinishedAction(this.state))
                      }
                      name={registerFormFieldNames.identifyDocumentSelfie}
                      title={`${fields.identifyDocumentSelfie}:`}
                    />
                  </RegisterFormImageUploadWrapper>
                </RegisterFormImageUploadesWrapper>

                <div style={{ marginBottom: '15px', marginTop: '40px' }}>
                  <AgbCheckbox
                    isChecked={this.state.isAgbCheckboxChecked}
                    onClick={() =>
                      this.setState(toogleAgbCheckboxAction(this.state))
                    }
                    onClickLabelText={this.openAgbModal}
                  />
                </div>
                <SubmitButton />
              </FormikForm>
            )}
          </Formik>

          <ConfirmationModal
            isModalOpen={this.state.isConfirmationModalOpen}
            onCloseModal={this.handleOnCloseModal}
          />

          <UploadProgressModal
            uploadProgressInPercent={getUploadProgressInPercent(
              this.state.uploadProgress
            )}
            isModalOpen={this.state.isUploadProgressModalOpen}
            onCancelUpload={this.handleOnCancelUploadFormData}
          />

          <AgbModal
            isModalOpen={this.state.isAgbModalOpen}
            onCloseModal={this.closeAgbModal}
          />
        </SemanticUiForm>
      </Segment>
    );
  }
}
