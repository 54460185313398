import { FormFields } from './FormFields';
import { Select, Input } from 'semantic-ui-react';
import { FormFieldType } from './FormFieldType.enum';

interface InitialFormValues {
  salutationSelect: string;
  firstName: string;
  lastName: string;
  street: string;
  streetNumber: string;
  zipcode: string;
  city: string;
  country: string;
  nationality: string;
  birthdayDay: string;
  birthdayMonth: string;
  birthdayYear: string;
}

export const registerFormFields: FormFields = {
  salutationSelect: {
    name: 'salutationSelect',
    control: Select,
    options: [
      { key: 'mister', value: 'mister', text: 'Herr' },
      { key: 'miss', value: 'miss', text: 'Frau' },
    ],
  },
  firstName: {
    name: 'firstName',
  },
  lastName: {
    name: 'lastName',
  },
  street: {
    name: 'street',
  },
  streetNumber: {
    name: 'streetNumber',
    type: FormFieldType.Text,
  },
  zipcode: {
    name: 'zipcode',
    type: FormFieldType.Number,
  },
  city: {
    name: 'city',
  },
  nationality: {
    name: 'nationality',
  },
  country: {
    name: 'country',
  },
  birthdayDay: {
    name: 'birthdayDay',
    type: FormFieldType.Number,
  },
  birthdayMonth: {
    name: 'birthdayMonth',
    type: FormFieldType.Number,
  },
  birthdayYear: {
    name: 'birthdayYear',
    type: FormFieldType.Number,
  },
  birthplace: {
    name: 'birthplace',
  },
  preMobileSelection: {
    name: 'preMobileSelection',
    width: '6',
    size: 'large',
    control: Select,
    options: [
      { text: '+30', value: '+30' },
      { text: '+31', value: '+31' },
      { text: '+32', value: '+32' },
      { text: '+33', value: '+33' },
      { text: '+34', value: '+34' },
      { text: '+355', value: '+355' },

      { text: '+359', value: '+359' },
      { text: '+36', value: '+36' },
      { text: '+380', value: '+380' },

      { text: '+381', value: '+381' },
      { text: '+385', value: '+385' },
      { text: '+386', value: '+386' },
      { text: '+387', value: '+387' },
      { text: '+39', value: '+39' },

      { text: '+40', value: '+40' },
      { text: '+41', value: '+41' },
      { text: '+420', value: '+420' },
      { text: '+421', value: '+421' },
      { text: '+423', value: '+423' },
      { text: '+43', value: '+43' },
      { text: '+44', value: '+44' },
      { text: '+45', value: '+45' },
      { text: '+46', value: '+46' },
      { text: '+47', value: '+47' },
      { text: '+48', value: '+48' },
      { text: '+49', value: '+49' },
    ],
  },
  mobileNumber: {
    width: '10',
    name: 'mobileNumber',
  },
  email: {
    name: 'email',
  },
  cardholderId: {
    name: 'cardholderId',
    type: FormFieldType.Number,
  },
  corporateWalletId: {
    name: 'corporateWalletId',
    type: FormFieldType.Number,
  },
  walletId: {
    name: 'walletId',
    type: FormFieldType.Number,
  },
  identifyDocumentFront: {
    name: 'identifyDocumentFront',
  },
  identifyDocumentBack: {
    name: 'identifyDocumentBack',
  },
  identifyDocumentSelfie: {
    name: 'identifyDocumentSelfie',
  },
  creditCardPicture: {
    name: 'creditCardPicture',
  },
};
