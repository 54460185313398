import React from 'react';
import { Button, Header, Icon, Modal } from 'semantic-ui-react';
import { ConfirmationModalProps } from './ConfirmationModal.interface';
import { messages } from '../../messages';
import { Messages } from '../../messages/Messages.interface';
// import { Messages } from '../../../messages/Messages.interface';
// import { messages } from '../../../messages';

const {
  registerForm: { confirmationModal},
}: Messages = messages;

export const ConfirmationModal: React.SFC<ConfirmationModalProps> = props => (
  <Modal size="tiny" open={props.isModalOpen}>
    {/* <Header icon="checkmark" content={confirmationModal.title} /> */}
    <Header><Icon name="checkmark" color="green" />{confirmationModal.title}</Header>
    <Modal.Content>
      <p>
        {confirmationModal.content}
      </p>
    </Modal.Content>
    <Modal.Actions>
      <Button color="green" onClick={props.onCloseModal}>
        <Icon name="checkmark" /> {confirmationModal.okButton}
      </Button>
    </Modal.Actions>
  </Modal>
);
