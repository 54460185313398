import React from 'react';
import { Button, Header, Icon, Modal, Progress } from 'semantic-ui-react';
import { messages } from '../../messages';
import { Messages } from '../../messages/Messages.interface';
import { UploadProgress } from './RegisterForm.interface';
// import { Messages } from '../../../messages/Messages.interface';
// import { messages } from '../../../messages';

type UploadProgressModalProps = {
  uploadProgressInPercent: number;
  isModalOpen: boolean;
  onCancelUpload: () => void;
};

const {
  registerForm: { uploadProgressModal },
}: Messages = messages;

export const UploadProgressModal: React.SFC<UploadProgressModalProps> = ({
  isModalOpen,
  uploadProgressInPercent,
  onCancelUpload,
}) => (
  <Modal size="tiny" open={isModalOpen}>
    {/* <Header icon="checkmark" content={confirmationModal.title} /> */}
    <Header>
      <Icon name="cloud upload" color="green" />
      {uploadProgressModal.title}
    </Header>
    <Modal.Content>
      <p>{uploadProgressModal.content} ...</p>
      <Progress percent={uploadProgressInPercent} success />
    </Modal.Content>
    <Modal.Actions>
      <Button color="red" onClick={onCancelUpload}>
        <Icon name="cancel" /> {uploadProgressModal.cancelButton}
      </Button>
    </Modal.Actions>
  </Modal>
);
