import * as React from 'react';
import { Field, FieldProps } from 'formik';
import loadImage from 'blueimp-load-image';

import { ImageUpload } from '../ImageUploadField';
import { CONSTANTS } from '../../constants';

export interface ImageFieldValue {
  imgSrc?: string | ArrayBuffer;
  extra?: string;
}

interface RegisterFormImageUploadProps {
  name: string;
  value?: ImageFieldValue;
  title: string;
  isUploadDisabled: boolean;
  onImageLoadingStarted(): void;
  onImageLoadingFinished(): void;
}

type TRegisterFormImageUploadState = {
  isImageLoading: boolean;
};

const deleteLastFile = (files: any[]): void => {
  files.pop();
};

const resizeImage = (
  imageSrcBase64: any,
  fileName: string,
  qualityReduceFactor: number
): Promise<string> => {
  const img = new Image();
  img.src = imageSrcBase64;

  return new Promise(resolve => {
    img.onload = () => {
      const elem = document.createElement('canvas');
      elem.width = img.width;
      elem.height = img.height;

      const ctx = elem.getContext('2d');
      ctx.drawImage(img, 0, 0, elem.width, elem.height);

      ctx.canvas.toBlob(
        blob => {
          const reader = new FileReader();
          const file = new File([blob], fileName, {
            type: 'image/jpeg',
            lastModified: Date.now(),
          });
          reader.readAsDataURL(file);

          reader.onload = (event: any) => {
            const imageSrcBase64 = event.target.result;

            resolve(imageSrcBase64);
          };
        },
        'image/jpeg',
        qualityReduceFactor
      );
    };
  });
};

const imageSelectedAction = (
  state: TRegisterFormImageUploadState
): TRegisterFormImageUploadState => {
  return { ...state, isImageLoading: true };
};

const imageLoadedAction = (
  state: TRegisterFormImageUploadState
): TRegisterFormImageUploadState => {
  return { ...state, isImageLoading: false };
};

export class RegisterFormImageUpload extends React.Component<
  RegisterFormImageUploadProps,
  TRegisterFormImageUploadState
> {
  constructor(props) {
    super(props);
    this.state = {
      isImageLoading: false,
    };
  }
  render() {
    const {
      title,
      name,
      onImageLoadingStarted,
      onImageLoadingFinished,
    } = this.props;
    return (
      <Field
        // important to pass name here
        name={name}
        render={(fieldProps: FieldProps) => {
          const imageFieldValue: ImageFieldValue = fieldProps.field.value || {
            imgSrc: '',
          };

          return (
            <ImageUpload
              title={title}
              imgSrc={imageFieldValue.imgSrc}
              isUploadDisabled={this.props.isUploadDisabled}
              isImageLoading={this.state.isImageLoading}
              onImageSelected={() => {
                console.log('selected');
                onImageLoadingStarted();
                this.setState(imageSelectedAction(this.state));
              }}
              onChange={(files: any[]) => {
                const reader = new FileReader();
                const file = files[0];

                try {
                  reader.readAsDataURL(file);
                } catch (error) {
                  alert('Uploaded image is too large!');
                }

                loadImage(
                  file,
                  async (imgCanvas: HTMLCanvasElement) => {
                    const newReader = new FileReader();
                    newReader.onload = async (e: any) => {
                      const imageSrcBase64 = imgCanvas.toDataURL();
                      const resizedImgSrcBase64 = await resizeImage(
                        imageSrcBase64,
                        file.name,
                        CONSTANTS.defaultQualityReduceFactor
                      );

                      const value: ImageFieldValue = {
                        imgSrc: resizedImgSrcBase64,
                        extra: file.extension,
                      };

                      const extendedEvent = {
                        ...event,
                        target: {
                          name,
                          value,
                        },
                      };

                      fieldProps.field.onChange(extendedEvent);
                      deleteLastFile(files);
                      onImageLoadingFinished();
                      this.setState(imageLoadedAction(this.state));
                    };
                    newReader.readAsDataURL(file);
                  },
                  { orientation: true }
                );
              }}
            />
          );
        }}
      />
    );
  }
}
