import React from 'react';
import { Messages } from '../../messages/Messages.interface';
import { messages } from '../../messages';
import { Modal, Header, Button } from 'semantic-ui-react';

export interface AgbModalProps {
  isModalOpen: boolean;
  onCloseModal: (() => void);
}

const {
  registerForm: { agbModal },
}: Messages = messages;

export const AgbModal: React.SFC<AgbModalProps> = props => (
  <Modal size="tiny" open={props.isModalOpen}>
    <Header>{agbModal.title}</Header>
    <Modal.Content>
      <Header>Default Profile Image</Header>
      <p>
        We've found the following gravatar image associated with your e-mail
        address.
      </p>
      <p>Is it okay to use this photo?</p>
    </Modal.Content>
    <Modal.Actions>
      <Button color="green" onClick={props.onCloseModal}>
        {agbModal.okButton}
      </Button>
    </Modal.Actions>
  </Modal>
);
