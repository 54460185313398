import React from 'react';
import { Checkbox } from 'semantic-ui-react';
import styled from 'styled-components';
import { Messages } from '../../messages/Messages.interface';
import { messages } from '../../messages';

interface AgbCheckboxProps {
  isChecked: boolean;
  onClick(): void;
  onClickLabelText: () => void;
}

const {
  registerForm: { agbCheckboxLabel },
}: Messages = messages;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CheckboxLabel = styled.a`
  cursor: pointer;
  margin-left: 0.5em;
`;

export const AgbCheckbox: React.SFC<AgbCheckboxProps> = ({
  onClickLabelText,
  isChecked,
  onClick,
}) => (
  <FlexWrapper>
    <Checkbox name="hallo" checked={isChecked} onClick={onClick} />

    <CheckboxLabel onClick={onClickLabelText}>{agbCheckboxLabel}</CheckboxLabel>
  </FlexWrapper>
);
