import * as React from 'react';
import { Field, FieldProps } from 'formik';
import { Form, Input, FormFieldProps } from 'semantic-ui-react';
import { FormFieldType } from '../FormFieldType.enum';
import { Messages } from '../../../messages/Messages.interface';
import { messages } from '../../../messages';

interface RegisterFormFieldProps extends FormFieldProps {
  name: string;
  value?: string;
  type?: FormFieldType;
}

const {
  registerForm: { fields },
}: Messages = messages;

export const RegisterFormField: React.SFC<RegisterFormFieldProps> = ({
  name,
  control = Input,
  value = '',
  ...rest
}) => (
  <Field
    // important to pass name here
    name={name}
    render={(fieldProps: FieldProps) => {
      return (
        <Form.Field
          label={fields[name]}
          placeholder={fields[name]}
          size="large"
          control={control}
          {...rest}
          {...fieldProps.field}
        />
      );
    }}
  />
);
