import * as React from 'react';
import { Form } from 'semantic-ui-react';
import { messages } from '../../messages';
import { Messages } from '../../messages/Messages.interface';
import styled from 'styled-components';

const {
  registerForm: { submitButtonLabel },
}: Messages = messages;

const StyledSubmitButtonWrapper = styled.div`
  width: 100%;
  height: auto;
  max-width: 300px;
  margin: auto;
  margin-top: 50px;
`;

export const SubmitButton = () => {
  return (
    <StyledSubmitButtonWrapper>
      <Form.Button type="submit" size="large" fluid>
        {submitButtonLabel}
      </Form.Button>
    </StyledSubmitButtonWrapper>
  );
};
