import * as React from 'react';
import styled from 'styled-components';
import Files from 'react-files';
import { Button, Loader } from 'semantic-ui-react';

interface ImageUploadFieldProps {
  title: string;
  imgSrc: any;
  isImageLoading: boolean;
  isUploadDisabled: boolean;
  onImageSelected(): void;
  onChange(files: any[]): void;
}

const BoldParagraph = styled.p`
  font-weight: bold;
  margin: 0 0 5px 0;
`;

const ImgWrapper = styled.div`
  & img {
    width: 100%;
    height: auto;
  }
`;

const ButtonWrapper = styled(Button)`
  width: 100%;
`;

const acceptFileTypes = ['image/*'];
const MaxWidthWrapper = styled.div`
  max-width: 300px;
`;

const LoaderWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding: 50px;
`;

const ImageLoadingSpinner = () => (
  <LoaderWrapper>
    <Loader active inline />
  </LoaderWrapper>
);

export const ImageUpload: React.SFC<ImageUploadFieldProps> = ({
  imgSrc,
  onChange,
  title,
  isUploadDisabled,
  isImageLoading,
  onImageSelected,
}) => (
  <React.Fragment>
    <BoldParagraph>{title}</BoldParagraph>
    <Files
      className="files-dropzone"
      onChange={images => {
        onImageSelected();
        onChange(images);
      }}
      accepts={acceptFileTypes}
      maxFileSize={10000000}
      minFileSize={0}
      clickable={!isUploadDisabled}
    >
      <MaxWidthWrapper>
        <ButtonWrapper
          disabled={isUploadDisabled}
          type="button"
          icon="upload"
        />

        {imgSrc && !isImageLoading ? (
          <ImgWrapper>
            <img src={imgSrc} />
          </ImgWrapper>
        ) : (
          isImageLoading && <ImageLoadingSpinner />
        )}
      </MaxWidthWrapper>
    </Files>
  </React.Fragment>
);
