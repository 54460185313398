import * as React from 'react';
import { FormSelectProps, Form } from 'semantic-ui-react';
import { Field, FieldProps } from 'formik';
import { Messages } from '../../../messages/Messages.interface';
import { messages } from '../../../messages';

interface RegisterFormSelectProps extends FormSelectProps {
  name: string;
}

const {
  registerForm: { submitButtonLabel, fields },
}: Messages = messages;

export const RegisterFormSelect: React.SFC<RegisterFormSelectProps> = ({
  name,
  ...rest
}) => (
  <Field
    render={(fieldProps: FieldProps) => {
      return (
        <Form.Select
          value={rest.value}
          label={fields[name]}
          placeholder={fields[name]}
          onChange={(event, dropDownProps) => {
            const extendedEvent = {
              ...event,
              target: { name, value: dropDownProps.value },
            };
            fieldProps.field.onChange(extendedEvent);
          }}
          size="large"
          {...rest}
        />
      );
    }}
  />
);
