import React from 'react';
import 'semantic-ui-css/semantic.min.css';

import Layout from '../components/layout';
import { RegisterForm } from '../components/RegisterForm/RegisterForm';

const IndexPage = () => (
  <Layout>
    <RegisterForm />
  </Layout>
);

export default IndexPage;
